import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {VideoPlayerComponent} from './pages/video-player/video-player.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'video-player',
    pathMatch: 'full'
  },
  {
    path: 'video-player',
    component: VideoPlayerComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
