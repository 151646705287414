import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {VideoLicenseService} from '../../services/video-license-service/video-license-service.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

  accessToken: string;
  poster: string;
  baseUrl: string;
  videoKey: string;
  type = 'hls';
  otp: string;
  cloudFrontKey: string;
  cloudFrontPolicy: string;
  cloudFrontSignature: string;
  master = 'master';
  source: Array<any>;
  playerConfig;

  constructor(
    private videoLicenseService: VideoLicenseService,
    private activatedRoute: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    this.setupInitialValues();
    this.initializePlayer();
  }

  setupInitialValues() {
    console.log('queryParams: ', this.activatedRoute.snapshot.queryParams);
    this.accessToken = this.activatedRoute.snapshot.queryParams['accessToken'];
    this.poster = this.activatedRoute.snapshot.queryParams['poster'];
    this.videoKey = this.activatedRoute.snapshot.queryParams['key'];
    this.type = this.activatedRoute.snapshot.queryParams['type'];
    this.baseUrl = this.activatedRoute.snapshot.queryParams['baseUrl'];
    this.otp = this.activatedRoute.snapshot.queryParams['otp'];
    this.master = this.activatedRoute.snapshot.queryParams.hasOwnProperty('index') ? "index" : "master";
    this.cloudFrontKey = this.activatedRoute.snapshot.queryParams['CloudFront-Key-Pair-Id'];
    this.cloudFrontPolicy = this.activatedRoute.snapshot.queryParams['CloudFront-Policy'];
    this.cloudFrontSignature = this.activatedRoute.snapshot.queryParams['CloudFront-Signature'];

    this.source = [];
    if (this.type === 'hls') {
      this.source.push({
        src: `${this.baseUrl}${this.videoKey}/${this.master}.m3u8`,
        type: 'application/x-mpegURL',
        withCredentials: false,
        cacheEncryptionKeys: true
      });

      if (this.cloudFrontPolicy && this.cloudFrontKey && this.cloudFrontSignature) {
        this.source[0].src = `${this.baseUrl}${this.videoKey}/${this.master}.m3u8?Policy=${this.cloudFrontPolicy}&Key-Pair-Id=${this.cloudFrontKey}&Signature=${this.cloudFrontSignature}`
      }
    }

    if (this.type === 'dash') {
      this.source.push({
        src: `${this.baseUrl}${this.videoKey}/${this.master}.mpd`,
        type: 'application/dash+xml',
        keySystems: {
          'org.w3.clearkey': {
            videoContentType: 'video/mp4;codecs="avc1.42c01e"',
            audioContentType: 'audio/mp4;codecs="mp4a.40.2"',
            getLicense: (emeOptions, keyMessage, callback) => {
              this.getLicenseFromServer(emeOptions, keyMessage, callback);
            }
          }
        }
      });
    }
  }

  async getLicenseFromServer(emeOptions, keyMessage, callback) {
    const request = JSON.parse(new TextDecoder().decode(keyMessage));
    try {
      const keyObj = {
        kty: 'oct',
        kid: request.kids[0],
        k: this.videoLicenseService.hexToBase64(this.videoLicenseService.decrypt(this.accessToken, this.otp)) // This key should be come from server
      };

      // console.log('key-value: ', this.videoLicenseService.decrypt(this.globalService.accessToken, response['data'].otp));
      callback(null, new TextEncoder().encode(JSON.stringify({
        keys: [keyObj]
      })));
    } catch (e) {
      console.log(e);
    }
  }

  initializePlayer() {
    const config = {
      poster: this.poster,
      liveui: this.activatedRoute.snapshot.queryParams.hasOwnProperty('index') ? true : false,
      sources: this.source,
      autoplay: true,
      startTime: 0,
      fullScreenEnabled: false,
      responsive: true, // fluid || fill || responsive
      seekButtons: true, // Add plugin first
      seekSeconds: 10, // Add plugin first
      defaultQuality: 'Auto', // Auto|'240'|'360'...
      watermark: { // Add plugin first
        text: 'Watermark',
      }
    };

    if (this.type === 'hls' && this.cloudFrontPolicy && this.cloudFrontKey && this.cloudFrontSignature) {
      config['query'] = `?Policy=${this.cloudFrontPolicy}&Key-Pair-Id=${this.cloudFrontKey}&Signature=${this.cloudFrontSignature}`;
    }
    this.playerConfig = config;
  }

}
