import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideoLicenseService {

  b64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

  constructor(
  ) {
  }

  hexToBase64(str) {
    return btoa(String.fromCharCode.apply(null,
      str.replace(/\r|\n/g, '').replace(/([\da-fA-F]{2}) ?/g, '0x$1 ').replace(/ +$/, '').split(' '))
    ).replace(/\+/g, '-').replace(/\//g, '_').replace(/=*$/, '');
  }

  base64ToHex(str) {
    str = str.replace(/\-/g, '+').replace(/\_/g, '/');
    for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, '')), hex = []; i < bin.length; ++i) {
      let tmp = bin.charCodeAt(i).toString(16);
      if (tmp.length === 1) {
        tmp = '0' + tmp;
      }
      hex[hex.length] = tmp;
    }
    return hex.join('');
  }

  // base64ToHex(str) {
  //     for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, '')), hex = []; i < bin.length; ++i) {
  //         let tmp = bin.charCodeAt(i).toString(16);
  //         if (tmp.length === 1) tmp = '0' + tmp;
  //         hex[hex.length] = tmp;
  //     }
  //     return hex.join('');
  // }

  private b64_encode(data) {
    let o1, o2, o3, h1, h2, h3, h4, bits, r, i = 0, enc = '';
    if (!data) {
      return data;
    }
    do {
      o1 = data[i++];
      o2 = data[i++];
      o3 = data[i++];
      bits = o1 << 16 | o2 << 8 | o3;
      h1 = bits >> 18 & 0x3f;
      h2 = bits >> 12 & 0x3f;
      h3 = bits >> 6 & 0x3f;
      h4 = bits & 0x3f;
      enc += this.b64.charAt(h1) + this.b64.charAt(h2) + this.b64.charAt(h3) + this.b64.charAt(h4);
    } while (i < data.length);
    r = data.length % 3;
    return (r ? enc.slice(0, r - 3) : enc) + '==='.slice(r || 3);
  }

  private b64_decode(data) {
    let o1, o2, o3, h1, h2, h3, h4, bits, i = 0, result = [];
    if (!data) {
      return data;
    }
    data += '';
    do {
      h1 = this.b64.indexOf(data.charAt(i++));
      h2 = this.b64.indexOf(data.charAt(i++));
      h3 = this.b64.indexOf(data.charAt(i++));
      h4 = this.b64.indexOf(data.charAt(i++));
      bits = h1 << 18 | h2 << 12 | h3 << 6 | h4;
      o1 = bits >> 16 & 0xff;
      o2 = bits >> 8 & 0xff;
      o3 = bits & 0xff;
      result.push(o1);
      if (h3 !== 64) {
        result.push(o2);
        if (h4 !== 64) {
          result.push(o3);
        }
      }
    } while (i < data.length);
    return result;
  }

  private keyCharAt(key, i) {
    return key.charCodeAt(Math.floor(i % key.length));
  }

  private xor_encrypt(key, data) {
    return Object.assign([], data).map((c, i) => {
      return c.charCodeAt(0) ^ this.keyCharAt(key, i);
    });
  }

  private xor_decrypt(key, data) {
    return Object.assign([], data).map((c, i) => {
      return String.fromCharCode(c ^ this.keyCharAt(key, i));
    }).join('');
  }

  encrypt(key, data) {
    data = this.xor_encrypt(key, data);
    return this.b64_encode(data);
  }

  decrypt(key, data) {
    data = this.b64_decode(data);
    return this.xor_decrypt(key, data);
  }
}
